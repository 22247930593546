//
// Form controls
//

// Customization
.button{
  padding: 14px 32px;
  width: "100%";
  height: 56px;  
  border-radius: 10px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  
}

.button-tab{
  width: "100%";
  height: 34px;  
  border-radius: 12px;
  cursor: pointer;
  text-align: center;   
  color: $primary;  
  font-family: "Montserrat-regular";
  padding: 6px; 
}


.button-tab.active{
  width: "100%";
  height: 34px;  
  border-radius: 12px;
  font-weight: bold;
  cursor: pointer;
  text-align: center; 
  background-color: #F0EFF2;
  color: $primary;
  border: 1px solid $primary;
  font-family: "Montserrat-regular";
  padding: 6px; 
}

.button-primary{
  background: linear-gradient(90deg, #A058A3 -100.3%, #2F2E7C 100.24%);
  color: white;
}

.button-secondary{
  background-color: #F0EFF2;
  color: $primary;
  border: 1px solid $primary;
  font-family: "Montserrat-regular";
  padding: auto 32px;
}

.button-secondary.w-full{
  width: 100%;
  height: 100%;
  justify-content: center;

}


.button-secondary span{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.button-primary-rounded{
  background: linear-gradient(90deg, #A058A3 -100.3%, #2F2E7C 100.24%);
  color: white;
  border-radius: 10px;
}

.button-primary-rounded:disabled, .button-primary:disabled, .button-primary[disabled], 
.button-primary-rounded[disabled]{
  background: linear-gradient(90deg, $gray-100 -100.3%, $gray-100 100.24%);
  color: $gray-400;
}

.button-primary-rounded-red{
  background: $semantic-red;
  color: white;
  border-radius: 10px;
}

.button-primary-rounded span{
  margin-left: 20px;
  margin-right: 20px
}

.button-primary-rounded-red span{
  margin-left: 20px;
  margin-right: 20px
}

.button-primary-rounded-red:hover{
  background-color: $semantic-red;
  color: white;
}

.delete-option, .delete-option:hover{
  color: $semantic-red;
}

.button-rounded{
  border-radius: 10px;
  min-height: 48px; 
}

.button-primary-rounded:hover{
  background-image: linear-gradient(90deg, #2F2E7C -100.3%, #A058A3 100.24%);
  color: white;
}

.button-primary:not(:disabled):hover{
  background-image: linear-gradient(90deg, #2F2E7C -100.3%, #A058A3 100.24%);
  color: white;
}

.text-primary-color{
  color: $primary;
  display: inline;
  line-break: anywhere;
  line-height: 39px;
  width: auto;
}

.text-underline {
  text-decoration: underline;
}

.text-purple{
  color: $secondary-malva;
}

.text-underline:hover {
  text-decoration: underline;
}

.indicator-label{
  font-family: "Montserrat-regular";
  font-weight: 300;
  font-size: $btn-font-size-lg;
}

.indicator-label-small{
  font-family: "Poppins-bold";
  font-weight: 300;
  font-size: $btn-font-size-md;
}

.text-blue{
  color: #3699FF
}

.text-accent-blue{
  color: $accent-blue;
}

.main-title{
  font-family: "Poppins-bold";
  font-size: 32px;
  line-height: 48px;
  font-weight: $font-weight-bold;
}
.page-heading{
  font-size: 36px;
  font-family: "Montserrat-regular";
}
.button-black{
  color: white;
  background-color: #000;
}
.form-label{
  font-family: "Poppins-bold";
  margin-bottom: 6px;
  font-size: 20px;
  color: $gray-800;
}

.form-label-min{
  font-family: "Poppins-bold";
  margin-bottom: 6px;
  font-size: 16px;
  color: $gray-800;
}

#togglePassword{
  font-size: 2rem;
  color: #171717;
  cursor: pointer;
}

.menu-title{
  font-family: 'Poppins-regular';
  font-style: normal;
  font-weight: 500;  
  line-height: 21px;
  font-size: 16px;
  margin-left: 16px;
  margin-right: 16px;
}

.form-control {
  // Transparent style
  &.form-control-transparent {
    background-color: transparent;
    border-color: transparent;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: transparent;
      border-color: transparent;
    }
  }

  // Flush
  &.form-control-flush {
    @include input-reset();
  }
}

// Solid style
.form-control{
  padding-top: 16px;  
  padding-bottom: 16px;
  font-size: 16px;
}
.form-control.form-control-solid {
  background-color: $input-solid-bg;
  border-color: $input-solid-bg;
  color: $input-solid-color;
  @include placeholder($input-solid-placeholder-color);
  transition: $transition-input;

  .dropdown.show > &,
  &:active,
  &.active,
  &:focus,
  &.focus {
    background-color: $input-solid-bg-focus;
    border-color: $input-solid-bg-focus;
    color: $input-solid-color;
    transition: $transition-input;
  }
}

// Form control solid bg
.form-control-solid-bg {
  background-color: $input-solid-bg;
}

// Readonly controls as plain text
.form-control-plaintext {
  color: $input-plaintext-color;
}

// Placeholder colors
.placeholder-gray-500 {
  @include placeholder(var(--#{$prefix}gray-500));
}

.placeholder-white {
  @include placeholder($white);
}

// Textarea reset resize
.resize-none {
  resize: none;
}

.modal-content{
  padding: 14px 26px;
  border-radius: 20px;
}

.modal-header{
  margin-bottom: 0;
}

.modal-title{
  font-family: "Montserrat-semiBold";
  font-size: 28px;
}

.modal-body{
  margin-top: -20px;
}

.modal-body ul{
  line-height: 44px;
}


/*Toast*/

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  border: none;
  box-shadow: none;
}

.Toastify__toast-body {
  font-family: "Poppins-regular";
  color:#000000;
  font-size: 20px;
}


.Toastify__toast-theme--light.Toastify__toast--success {
  border: 1px solid $semantic-green;
  padding: 10px;
  width: 520px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; 
}

.Toastify__toast-theme--light.Toastify__toast--error {
  border: 1px solid $semantic-red;
  padding: 10px;
  width: 520px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; 
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  visibility: hidden;
}

.avatar-image{
  height: 60px;
  width: 60px;
  object-fit: contain;
  border-radius: 50%;
}


.code-container{
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.code-character {
  height: 88px;
  width: 88px;
  color: $gray-800;
  border-radius: 15px;  
  border-width: 1px;
  margin: 12px;
  align-items: center;
  vertical-align: center;
  padding-top: 18px;
  border-color: $semantic-green;
}
@media (max-width: 600px) {
  .code-character {
    width: 64px;
    height: 64px;
    margin: 8px;
    padding-top: 8px;
  }
}

@media only screen and (max-width: 1170px) and (min-width: 992px){
  .code-character {
    width: 64px;
    height: 64px;
    margin: 8px;
    padding-top: 8px;
  }
}

@media (max-width: 450px) {
  .code-character {
    width: 50px;
    height: 50px;
    margin: 4px;
    padding-top: 2px;
  }
}

.code-character--inactive{
  background-color: #fff;  
  border-color: $gray-400;
}

.code-character--selected{
  border-color: $semantic-green;
}

.icon-left-inside {
  position: absolute;
  left: 10px;
  top: calc(50% - 12px);
  pointer-events: none;
  font-size: 16px;
  font-size: 1.125rem;
  z-index:3;
  background-color: transparent;
}

.icon-right-inside {
  position: absolute;
  right: 12px;
  top: calc(50% - 12px);  
  font-size: 16px;
  z-index:10;
  background-color: transparent;
}

.input-group:has(> .is-valid) .icon-right-inside, .input-group:has(> .is-invalid) .icon-right-inside{
  right: 40px;
}


.text-right{
  text-align: right;
}

.fs-3{
  font-size: 16px;
}

.mw-1137px{
  
  min-width: 1137px;
}

@media (max-width: 1137px) {
  .mw-1137px{
    min-width: 100%;
  }
}

.button-outline{
  border: 1px solid #3699FF;
}

.container-fluid{
  margin: 0;
  padding: 0
}

.btnDownloadPdf{
  position: absolute;
}

@media (max-width: 650px) {
  .btnDownloadPdf{
    position: initial;
  }
}

.spinner{
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.flagLang .active{background-color: $gray-100; padding: 12px; border-radius: 10px;}

